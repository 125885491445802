import { InjectionToken } from '@angular/core';

import { environment } from '@app/environments/environment';
import { IHamburgerTokenData } from '@hamburger/interfaces/ihamburger-token-data';
import { IInfoLinkItem, LinkAction } from '@infoLinks/components/info-links/info-links.component';

/**
 * Токен для инжектирования конфигурации гамбургер-меню
 */
export const HAMBURGER_DATA_TOKEN: InjectionToken<IHamburgerTokenData> = new InjectionToken<IHamburgerTokenData>(
	'HAMBURGER_DATA_TOKEN'
);

/**
 * Список ссылок на информационные ресурсы в меню-гамбургер.
 */
export const HAMBURGER_LINK_LIST: Array<IInfoLinkItem> = [
	{
		label: 'info-panel.game-rules-mines',
		isVisibleIcons: false,
		action: LinkAction.Rules,
		url: environment.urlRulesMines
	},
	{
		label: 'info-panel.game-rules-tower',
		isVisibleIcons: false,
		action: LinkAction.Rules,
		url: environment.urlRulesTower
	},
	{
		label: 'info-panel.game-rules-smile',
		isVisibleIcons: false,
		action: LinkAction.Rules,
		url: environment.urlRulesSmile
	},
	{
		label: 'info-panel.game-rules-crash',
		isVisibleIcons: false,
		action: LinkAction.Rules,
		url: environment.urlRulesCrash
	},
	{
		label: 'info-panel.game-rules-tod',
		isVisibleIcons: false,
		action: LinkAction.Rules,
		url: environment.urlRulesTod
	},
	{
		label: 'info-panel.game-rules-buhta',
		isVisibleIcons: false,
		action: LinkAction.Rules,
		url: environment.urlRulesBuhta
	},
	// {
	// 	label: 'info-panel.game-rules-color',
	// 	isVisibleIcons: false,
	// 	action: LinkAction.Rules,
	// 	url: environment.urlRulesColor
	// },
	{
		label: 'info-panel.game-conditions',
		isVisibleIcons: false,
		action: LinkAction.Terms,
		url: environment.urlTermsAndConditions
	}
];
