import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { MiniGamesCodes } from '@app/core/enums/mini-games-list.enum';
import { CheckServiceConnectionGuard } from '@app/core/guards/check-service-connection-guard.service';
import {
	URL_GAME_BUHTA, URL_GAME_BUHTA_OLD, URL_GAME_COLOR,
	URL_GAME_CRASH, URL_GAME_CRASH_OLD,
	URL_GAME_LIST,
	URL_GAME_LIVE, URL_GAME_MINES,
	URL_GAME_MINES_OLD, URL_GAME_SMILE,
	URL_GAME_SMILE_OLD,
	URL_GAME_TOD, URL_GAME_TOD_OLD,
	URL_GAME_TOWER, URL_GAME_TOWER_OLD,
	URL_NOT_FOUND
} from '@app/utils/route-utils';

/**
 * Список маршрутов.
 */
export const routes: Routes = [

	// -----------------------------
	//  Features
	// -----------------------------

	{
		path: '',
		loadChildren: () => import('@app/pages/game-list/game-list.module')
			.then(m => m.GameListModule),
		canActivate: [
			CheckServiceConnectionGuard
		],
		data: { moduleUrl: URL_GAME_LIST },
		// redirectTo: URL_GAME_MINES,
		// redirectTo: URL_GAME_REDIRECT,
		matcher: (segments: UrlSegment[], group: UrlSegmentGroup, route: Route) => {
			if (route.path === '') {
				return {consumed: segments};
			}

			return undefined;
		}
	},
	{
		path: URL_GAME_LIVE,
		loadChildren: () => import('@app/pages/win-table-mobile/win-table-mobile.module')
			.then(m => m.WinTableMobileModule),
		canActivate: [
			CheckServiceConnectionGuard
		],
		data: { moduleUrl: URL_GAME_LIVE }
	},
	// {
	// 	path: URL_GAME_REDIRECT,
	// 	loadChildren: () => import('@page/redirect/redirect.module')
	// 		.then((m: { RedirectModule: RedirectModule }) => m.RedirectModule)
	// },

	// -----------------------------
	//  Game lazy modules
	// -----------------------------

	{
		path: URL_GAME_MINES,
		loadChildren: () => import('@mines/mines.module')
			.then(m => m.MinesModule),
		canActivate: [
			CheckServiceConnectionGuard
		],
		data: { moduleUrl: URL_GAME_MINES, gameCode: MiniGamesCodes.Mines }
	},

	{
		path: URL_GAME_TOWER,
		loadChildren: () => import('@tower/tower.module')
			.then(m => m.TowerModule),
		canActivate: [
			CheckServiceConnectionGuard
		],
		data: { moduleUrl: URL_GAME_TOWER, gameCode: MiniGamesCodes.Tower }
	},

	{
		path: URL_GAME_SMILE,
		loadChildren: () => import('@smile/smile.module')
			.then(m => m.SmileModule),
		canActivate: [
			CheckServiceConnectionGuard
		],
		data: { moduleUrl: URL_GAME_SMILE, gameCode: MiniGamesCodes.Smile }
	},

	{
		path: URL_GAME_CRASH,
		loadChildren: () => import('@crash/crash.module')
			.then(m => m.CrashModule),
		canActivate: [
			CheckServiceConnectionGuard
		],
		data: { moduleUrl: URL_GAME_CRASH, gameCode: MiniGamesCodes.Crash }
	},

	{
		path: URL_GAME_TOD,
		loadChildren: () => import('@tod/tod.module')
			.then(m => m.TodModule),
		canActivate: [
			CheckServiceConnectionGuard
		],
		data: { moduleUrl: URL_GAME_TOD, gameCode: MiniGamesCodes.Tod }
	},

	{
		path: URL_GAME_BUHTA,
		loadChildren: () => import('@buhta/buhta.module')
			.then(m => m.BuhtaModule),
		canActivate: [
			CheckServiceConnectionGuard
		],
		data: { moduleUrl: URL_GAME_BUHTA, gameCode: MiniGamesCodes.Buhta }
	},

	// {
	// 	path: URL_GAME_SQUID,
	// 	loadChildren: () => import('@squid/squid.module')
	// 		.then(m => m.SquidModule),
	// 	canActivate: [
	// 		CheckServiceConnectionGuard
	// 	],
	// 	data: { moduleUrl: URL_GAME_SQUID, gameCode: MiniGamesCodes.Squid }
	// },

	// {
	// 	path: URL_GAME_COLOR,
	// 	loadChildren: () => import('@color/color.module')
	// 		.then(m => m.ColorModule),
	// 	canActivate: [
	// 		CheckServiceConnectionGuard
	// 	],
	// 	data: { moduleUrl: URL_GAME_COLOR, gameCode: MiniGamesCodes.Color }
	// },
	
	{
		path: URL_GAME_MINES_OLD,
		redirectTo: URL_GAME_MINES,
		pathMatch: 'prefix'
	},

	{
		path: URL_GAME_TOWER_OLD,
		redirectTo: URL_GAME_TOWER,
		pathMatch: 'prefix'
	},

	{
		path: URL_GAME_SMILE_OLD,
		redirectTo: URL_GAME_SMILE,
		pathMatch: 'prefix'
	},

	{
		path: URL_GAME_CRASH_OLD,
		redirectTo: URL_GAME_CRASH,
		pathMatch: 'prefix'
	},

	{
		path: URL_GAME_TOD_OLD,
		redirectTo: URL_GAME_TOD,
		pathMatch: 'prefix'
	},

	{
		path: URL_GAME_BUHTA_OLD,
		redirectTo: URL_GAME_BUHTA,
		pathMatch: 'prefix'
	},

	// {
	// 	path: URL_GAME_LIST,
	// 	loadChildren: () => import('@app/pages/game-list/game-list.module')
	// 		.then(m => m.GameListModule),
	// 	canActivate: [
	// 		CheckServiceConnectionGuard
	// 	],
	// 	data: { moduleUrl: URL_GAME_LIST },
	// 	// redirectTo: URL_GAME_MINES,
	// 	// redirectTo: URL_GAME_REDIRECT,
	// 	pathMatch: 'full'
	// },
	// ...
	// ...
	// ...

	// -----------------------------
	//  Path not found
	// -----------------------------

	{
		path: URL_NOT_FOUND,
		loadChildren: () => import('@app/pages/not-found/not-found.module')
			.then(m => m.NotFoundModule),
		data: { moduleUrl: URL_NOT_FOUND }
	},

	{
		path: '**',
		redirectTo: URL_NOT_FOUND,
		pathMatch: 'full'
	}

];

/**
 * Корневой модуль маршрутизации.
 */
@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [
		RouterModule
	]
})
export class AppRoutingModule {}
